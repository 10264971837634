
import { Component, Mixins, Prop } from "vue-property-decorator";
import { title, role } from "../../pipes";
import CircledAvatar from "../ui/CircledAvatar.vue";
import ContextMenu from "../ui/ContextMenu.vue";
import { VuetifyMixin, MyTeamsMixin, AppHostnameMixin, BAIconsMixin, AthleteRoutingMixin } from "@/mixins";
import { TeamDetailsMixin } from "../../mixins/TeamDetailsMixin";
import { ContextMenuItem, InviteStaffFormValue } from "@/../types/interfaces";
import { EditTeam } from "@/../types/constants/web_client_user.routes";
import { teamCreateStore, userStore, notificationStore } from "@/store";
import { NotificationModel } from '@/models/notification/NotificationModel';
import { StaffMember } from '@/models/team/StaffMember';
import { InvitationStatus } from '@/../types/enums';
import InviteStaffDialog from '@/components/teams/InviteStaffDialog.vue';
import TeamHeadingCard from '@/components/teams/TeamHeadingCard.vue';
import { TeamModel } from '@/models/team';
import { Route } from 'vue-router';
import TeamRecord from './TeamRecord.vue';

@Component({
	components: { CircledAvatar, ContextMenu, TeamHeadingCard, InviteStaffDialog, TeamRecord },
	filters: { title }
})
export default class TeamDetailsCardV2 extends Mixins(
	TeamDetailsMixin,
	VuetifyMixin,
	MyTeamsMixin,
	AppHostnameMixin,
	BAIconsMixin,
	AthleteRoutingMixin,
) {
	roleToStr = role;

	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop({ type: Boolean, default: false }) readOnly: boolean;
	showConfirmLeaveTeam: boolean = false;
	showInviteDialog: boolean = false;
	staffInviteId: string = "";
	staffInvite: StaffMember = null;

	get OtherTeams(): TeamModel[]{
		if(this.AthleteAppActive){
			return this.MyAthleteTeams.filter(t => t.id !== this.team.id);
		}
		return this.MyTeams.filter(t => t.id !== this.team.id);
	}
	get HasMoreTeams(): boolean{
		return this.OtherTeams.length > 0;
	}

	coachInviteForm: InviteStaffFormValue = {
		firstName: "",
		lastName: "",
		email: "",
		role: undefined,
	};

	teamsMenuOpen: boolean = false;
	showAllStaff: boolean = false;

	inviteCoach(staff: StaffMember){
		this.staffInviteId = staff.id;
		this.coachInviteForm = {
			firstName: staff.firstName,
			lastName: staff.lastName,
			email: staff.email,
			role: staff.role,
		};
		this.staffInvite = staff;
		this.showInviteDialog = true;
	}

	get StaffMembers(): StaffMember[]{
		return this.team.staffMembers.slice(0,3);
	}
	get AllStaffMembers(): StaffMember[]{
		return this.team.staffMembers;
	}
	get HasMoreStaff(): boolean{
		return this.AllStaffMembers.length > this.StaffMembers.length;
	}

	CoachInvitationPending(staff: StaffMember): boolean {
		if (staff.coachId) return false
		return staff.invitationStatus === InvitationStatus.Pending && userStore.coachId !== staff.coachId;
	}

	isCoachInvited(staff: StaffMember): boolean{
		return staff.invitationStatus === InvitationStatus.None;
	}

	didCoachAccept(staff: StaffMember): boolean{
		return !!staff.coachId || staff.invitationStatus === InvitationStatus.Accepted
	}

	get showInviteOrResend(): boolean {
		return this.staffInvite && !this.staffInvite.coachId && this.staffInvite.invitationStatus === InvitationStatus.None;
	}

	copyEmails(staff: boolean, players: boolean) {
		if( !this.team ) return;

		var group = '';
		var emails = [];
		if( staff ) {
			for( const coach of this.team.staffMembers ) {
				if( !!coach.email ) {
					emails.push(coach.email);
				}
			}
			group = 'staff';
		}
		if( players ) {
			for( const player of this.team.players ) {
				if( !!player.email ) {
					emails.push(player.email);
				}
			}
			group = 'player';
		}
		if( staff && players ) group = 'team';

		const clipboardText = emails.join(", ");
		navigator.clipboard.writeText(clipboardText);

		notificationStore.pushSnackbarSuccess({message: `Copied '${emails.length}' ${group} emails to clipboard`});

	}
	copyStaffEmails() {
		this.copyEmails(true, false);
	}
	copyPlayerEmails() {
		this.copyEmails(false, true);
	}
	copyTeamEmails() {
		this.copyEmails(true, true);
	}

	get AthleteMenuItems(): ContextMenuItem[]{
		return [
			{
				icon: 'mdi-email',
				text: "Copy Staff Emails",
				click: () => this.copyStaffEmails()
			},
			{
				icon: 'mdi-email',
				text: "Copy Player Emails",
				click: () => this.copyPlayerEmails()
			},
			{
				icon: 'mdi-email',
				text: "Copy Team Emails",
				click: () => this.copyTeamEmails()
			},
			{
				icon: 'mdi-account-arrow-right-outline',
				text: "Leave Team",
				click: () => this.showLeaveTeamBox()
			},
		]
	}

	onEditTeam() {
		this.$router.push({
			name: EditTeam,
			params: {
				teamId: this.team.id
			}
		});
	}

	get CoachMenuItems(): ContextMenuItem[] {
		return [
			{
				icon: 'mdi-pencil',
				text: "Edit Team",
				click: () => this.onEditTeam()
			},
			{
				icon: 'mdi-email',
				text: "Copy Staff Emails",
				click: () => this.copyStaffEmails()
			},
			{
				icon: 'mdi-email',
				text: "Copy Player Emails",
				click: () => this.copyPlayerEmails()
			},
			{
				icon: 'mdi-email',
				text: "Copy Team Emails",
				click: () => this.copyTeamEmails()
			},
			{
				icon: 'mdi-account-arrow-right-outline',
				text: "Leave Team",
				click: () => this.showLeaveTeamBox()
			},
		];
	}
	async leaveTeam(): Promise<void> {
		this.showConfirmLeaveTeam = false;
		await teamCreateStore.leaveTeam({ teamId: this.team.id });
		await notificationStore.pushSnackbarSuccess(new NotificationModel(`You have left ${this.team.name}!`));
		this.$router.push({
			path: '/'
		});
	}
	showLeaveTeamBox(): void {
		this.showConfirmLeaveTeam = true;
	}

	cancelLeaveTeam(): void {
		this.showConfirmLeaveTeam = false;
	}
}
